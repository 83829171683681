<template>
  <div class="home" id="home">
    <!-- header -->
    <page-header :navIndex="0"></page-header>
    <!-- <div class="header flex">
      <img src="../assets/1.png" alt="" class="logo">
      <div class="flex">
        <div class="title " :class="navIndex == index ? 'choose_title' : ''" v-for="(item, index) in navList" :key="index"
          @click="navClick(index)">{{ item }}</div>
      </div>
      <div class="schoolBtn" @click="campusRecruitClick">
        校园招聘
      </div>
    </div> -->
    <!-- banner -->


    <el-carousel indicator-position="none" height="950px">
      <el-carousel-item v-for="(item, index) in bannerList" :key="index" style="height: 950px">
        <div class="banner">
          <div class="postion_left" style="width:1200px">
            <div class="title">{{ item.title }}</div>
            <div class="littleTitle">{{ item.littleTitle }}</div>
            <img src="../assets/92.png" alt="" class="BannerImage">
            <img src="../assets/93.png" alt="" class="BannerImageTwo">
          </div>
        </div>
        <!-- <img :src="item.img" alt="" class=""> -->
      </el-carousel-item>
    </el-carousel>

    <!-- main -->
    <div class="main">
      <div class="p_relative">
        <!--  -->
        <div class="companyMain">
          <!--  -->
          <div class="flex justify-between">
            <div class="company" v-for="(item, index) in companyList" :key="index">
              <div class="flex">
                <img :src="item.img" alt="" class="companyImg">
                <div>
                  <div class="companyName">{{ item.name }}</div>
                  <div class="flex">
                    <div class="tips">{{ item.career }}</div>
                    <div class="tips">{{ item.num }}</div>
                  </div>
                </div>
              </div>
              <div class="companyDesc">{{ item.desc }}</div>
              <div class="companyDetail" @click="lookDetail">查看详情></div>
            </div>
          </div>
          <!--  -->
          <div id="wrap">
            <div id="inner">
              <div class="flex">
                <div id="first" class="flex-wrap justify-between">
                  <img :src="item.src" alt="" class="otherCompany" v-for="item in otherCompany" :key="item">
                </div>
                <div id="second" class="flex-wrap justify-between">
                  <img :src="item.src" alt="" class="otherCompany" v-for="item in otherCompany" :key="item">
                </div>
              </div>
            </div>
          </div>
          <div id="wrap">
            <div id="inner">
              <div class="flex">
                <div id="first" class="flex-wrap justify-between">
                  <img :src="item.src" alt="" class="otherCompany" v-for="item in otherCompanyTwo" :key="item">
                </div>
                <div id="second" class="flex-wrap justify-between">
                  <img :src="item.src" alt="" class="otherCompany" v-for="item in otherCompanyTwo" :key="item">
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- 介绍视频 -->
      <div class="videoMain">
        <div>
          <div class="title">介绍视频</div>
          <div class="p_relative">
            <img src="../assets/17.png" alt="" class="oneVideo">
            <img src="../assets/18.png" alt="" class="play">
          </div>
          <div style="margin: 25px 0 0 0px;z-index: 99;height: 326px;background:#fff">
            <div class="flex postion_left">


              <div>
                <div class="p_relative">
                  <img src="../assets/19.png" alt="" class="smallVideo">
                  <img src="../assets/18.png" alt="" class="smallPlay">
                </div>
                <div class="name">职能匹配</div>
              </div>
              <div>
                <div class="p_relative">
                  <img src="../assets/20.png" alt="" class="smallVideo">
                  <img src="../assets/18.png" alt="" class="smallPlay">
                </div>
                <div class="name">职位分析</div>
              </div>
              <div>
                <div class="p_relative">
                  <img src="../assets/21.png" alt="" class="smallVideo">
                  <img src="../assets/18.png" alt="" class="smallPlay">
                </div>
                <div class="name">能力测评</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--人工智能匹配  -->
      <div class="contentMain">
        <div style="width: 1200px;top:110px" class="postion_left">
          <div class="flex">
            <div class="leftBox">
              <div class="name">人工智能匹配</div>
              <div class="desc">只需注册您的简历， AI就会推荐适合您的职位！</div>
              <img src="../assets/22.png" alt="" class="leftImg">
            </div>
            <img src="../assets/23.png" alt="" class="rightImg rightBox">
          </div>

          <div class="flex" style="margin-top: 110px;">
            <img src="../assets/24.png" alt="" class=" rightImg leftBoxOne">
            <div class="threeBox">
              <div class="name">人工智能匹配</div>
              <div class="desc">只需注册您的简历， AI就会推荐适合您的职位！</div>
              <img src="../assets/22.png" alt="" class="leftImg">
            </div>

          </div>
        </div>
      </div>
      <!--常见问题  -->
      <div class="sometimes postion_left">
        <div class="title">常见问题</div>
        <div class="help">帮助中心</div>
        <div class="listBox postion_left">
          <div v-for="(item, index) in questionList" :key="index">
            <div class="p_relative flex justify-between items-center list" @click="questionClick(index)">
              <div class="name">{{ item.name }}</div>
              <img src="../assets/26.png" alt="" v-if="questionIndex != index" class="rightJt">
              <img src="../assets/27.png" alt="" v-if="questionIndex == index" class="rightJt">
            </div>
            <div class="showList" v-if="questionIndex == index">
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
      <!-- 链接梦想 -->
      <div class="dream p_relative ">
        <div class="postion_left flex align-center" style="width: 1200px;">
          <img src="../assets/28.png" alt="" class="earth">
          <img src="../assets/29.png" alt="" class="name">
          <img src="../assets/30.png" alt="" class="hand">
          <img src="../assets/31.png" alt="" class="people">
        </div>
      </div>
    </div>
    <!-- footer -->
    <page-footer></page-footer>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  inject: ['reload'],
  data () {
    return {
      navIndex: 0,
      navList: ['首页', '招聘的公司', '评估体系', '帮助中心'],
      bannerList: [{
        title: '洞察真实情况，成就美好工作',
        littleTitle: '以科学严谨的测试题目，提供一站式就业服务'
      },
      ],
      companyList: [{
        img: require('../assets/zw-02.png'),
        name: '在洛阳',
        career: '互联网',
        num: '200人以上',
        desc: '五险一金，定期体检，加班补助，年终奖年终奖'
      }, {
        img: require('../assets/zw-02.png'),
        name: '在洛阳',
        career: '互联网',
        num: '200人以上',
        desc: '五险一金，定期体检，加班补助，年终奖年终奖'
      }, {
        img: require('../assets/zw-02.png'),
        name: '在洛阳',
        career: '互联网',
        num: '200人以上',
        desc: '五险一金，定期体检，加班补助，年终奖年终奖'
      }],
      otherCompany: [
        { src: require('../assets/zw-01.png') },
        { src: require('../assets/zw-01.png') },
        { src: require('../assets/zw-01.png') },
        { src: require('../assets/zw-01.png') },
        { src: require('../assets/zw-01.png') },
      ],
      otherCompanyTwo: [
        { src: require('../assets/zw-01.png') },
        { src: require('../assets/zw-01.png') },
        { src: require('../assets/zw-01.png') },
        { src: require('../assets/zw-01.png') },
        { src: require('../assets/zw-01.png') },
      ],
      // otherCompany: [
      //   { src: require('../assets/7.png') },
      //   { src: require('../assets/8.png') },
      //   { src: require('../assets/9.png') },
      //   { src: require('../assets/10.png') },
      //   { src: require('../assets/11.png') },
      // ],
      // otherCompanyTwo: [
      //   { src: require('../assets/12.png') },
      //   { src: require('../assets/13.png') },
      //   { src: require('../assets/14.png') },
      //   { src: require('../assets/15.png') },
      //   { src: require('../assets/16.png') },
      // ],
      questionList: [{
        name: '三才智聘职业测评是付费服务吗？',
        value: '三才智聘对所有普通用户免费开放，除了企业类是实行邀请制度，我们还计划为希望雇用我们的公司提供具有成本效益的付费计划。'
      }, {
        name: '我可以免费使用公司数据吗？',
        value: ''
      }, {
        name: '你如何邀请朋友与企业？',
        value: ''
      }, {
        name: '如果我想修改公司数据该怎么办？',
        value: ''
      }, {
        name: '利用人工智能创造您想要的人力资源？',
        value: ''
      },],
      questionIndex: -1,
    }
  },

  mounted () {

    this.$nextTick(() => {
      this.getLeft()
      this.getRightOne()
      this.getRight()
      this.getLeftOne()
    });
    this.$forceUpdate()
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.handleScrollSeven);
    window.removeEventListener('scroll', this.handleScrollEight);
    window.removeEventListener('scroll', this.handleScrollNine);
    window.removeEventListener('scroll', this.handleScrollTen);
    this.$forceUpdate()

    // console.log('销毁1',box);
  },
  methods: {
    lookDetail () {
      ElMessage({
        message: '正在建设中，敬请期待......',
        type: 'warning',
        offset: 100,
        customClass: 'messageIndex'
      })
    },
    getLeft () {
      //实例挂载完毕前
      var box = document.querySelector('.leftBox');
      var isVisible = false;

      this.handleScrollSeven = function () {
        var boxPosition = box.getBoundingClientRect().top;
        var windowHeight = window.innerHeight;
        if (boxPosition < windowHeight && !isVisible) {
          box.classList.remove('animate-out');
          box.classList.add('animate-in');
          isVisible = true;
        } else if (boxPosition >= windowHeight && isVisible) {
          box.classList.remove('animate-in');
          box.classList.add('animate-out');
          isVisible = false;
        }
      }
      window.addEventListener('scroll', this.handleScrollSeven);
    },
    getRightOne () {
      //
      var box = document.querySelector('.rightBox');
      var isVisible = false;

      this.handleScrollEight = function () {
        var boxPosition = box.getBoundingClientRect().top;
        var windowHeight = window.innerHeight;

        if (boxPosition < windowHeight + 300 && !isVisible) {
          box.classList.remove('animate-out-right');
          box.classList.add('animate-in-right');
          isVisible = true;
        } else if (boxPosition >= windowHeight && isVisible) {
          box.classList.remove('animate-in-right');
          box.classList.add('animate-out-right');
          isVisible = false;
        }
      }
      window.addEventListener('scroll', this.handleScrollEight);
    },
    getLeftOne () {
      //实例挂载完毕前
      var box = document.querySelector('.leftBoxOne');
      var isVisible = false;

      this.handleScrollNine = function () {
        var boxPosition = box.getBoundingClientRect().top;
        var windowHeight = window.innerHeight;

        if (boxPosition < windowHeight && !isVisible) {
          box.classList.remove('animate-out');
          box.classList.add('animate-in');
          isVisible = true;
        } else if (boxPosition >= windowHeight && isVisible) {
          box.classList.remove('animate-in');
          box.classList.add('animate-out');
          isVisible = false;
        }
      }
      window.addEventListener('scroll', this.handleScrollNine);
    },
    getRight () {
      //
      var box = document.querySelector('.threeBox');
      var isVisible = false;

      this.handleScrollTen = function () {
        var boxPosition = box.getBoundingClientRect().top;
        var windowHeight = window.innerHeight;

        if (boxPosition < windowHeight + 300 && !isVisible) {
          box.classList.remove('animate-out-right');
          box.classList.add('animate-in-right');
          isVisible = true;
        } else if (boxPosition >= windowHeight && isVisible) {
          box.classList.remove('animate-in-right');
          box.classList.add('animate-out-right');
          isVisible = false;
        }
      }
      window.addEventListener('scroll', this.handleScrollTen);
    },
    navClick (index) {
      this.navIndex = index
      this.reload()
      if (index == 1) {

        document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
        this.$router.push({
          path: '/companyView'
        })
      } else if (index == 2) {
        this.$router.push({
          path: '/estimateView'
        })
        document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
      } else if (index == 3) {
        this.$router.push({
          path: '/helpView'
        })
        document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
      } else {
        this.$router.push({
          path: '/'
        })
        document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
      }
    },
    campusRecruitClick () {
      let routeData = this.$router.resolve({ path: '/campusRecruit', });
      document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
      window.open(routeData.href, '_blank');
    },
    questionClick (index) {
      if (this.questionIndex == -1) {
        this.questionIndex = index
      } else {
        this.questionIndex = -1
      }

    },
  }
}
</script>
<style lang="scss" scoped>
.home {
  position: relative;
  z-index: 99;
}

// .header {
//   width: 100%;
//   height: 80px;
//   background: #fff;
//   position: fixed;
//   left: 0px;
//   top: 0px;
//   padding: 0px 360px;
//   line-height: 80px;
//   z-index: 9999;

//   .logo {
//     width: 110px;
//     height: 26px;
//     margin-top: 27px;
//   }

//   .title {
//     font-size: 16px;
//     font-family: PingFang SC, PingFang SC-500;
//     font-weight: 500;
//     color: #000000;
//     margin-left: 120px;
//     cursor: pointer;
//   }

//   .title:hover {
//     color: #4d3db9;
//   }

//   .choose_title {
//     color: #4d3db9;
//     border-bottom: 4px solid #4d3db9;
//     margin-bottom: 16px;
//   }

//   .schoolBtn {
//     width: 120px;
//     height: 40px;
//     background: linear-gradient(108deg, #b17bd2, #4d3db9 100%);
//     border-radius: 10px;
//     font-size: 16px;
//     font-family: PingFang SC, PingFang SC-500;
//     font-weight: 500;
//     text-align: center;
//     color: #ffffff;
//     line-height: 40px;
//     margin: 20px 0 0 240px;
//     cursor: pointer;
//   }
// }

//
.banner {
  position: absolute;
  left: 0px;
  top: 70px;
  width: 100%;
  height: 950px;
  background: url('../assets/2.png') no-repeat;

  .title {
    font-size: 70px;
    font-family: PingFang SC, PingFang SC-600;
    font-weight: 600;
    text-align: center;
    color: #333333;
    line-height: 70px;
    margin: 298px 0 0 0;
    white-space: nowrap;
  }

  .littleTitle {
    margin: 40px 0 324px 0;
    font-size: 28px;
    font-family: PingFang SC, PingFang SC-400;
    font-weight: 400;
    text-align: center;
    color: #333333;
  }

  .BannerImage {
    width: 477px;
    height: 446px;
    position: absolute;
    left: -100px;
    top: 398px;
  }

  .BannerImageTwo {
    width: 238px;
    height: 194px;
    position: absolute;
    right: 0px;
    top: 100px;
  }
}

//
.main {
  position: absolute;
  left: 0px;
  top: 930px;
  right: 0px;
  background: #f9fafe;
}

//
.companyMain {
  width: 1200px;
  // margin: 0 0px 50px 360px;
  position: absolute;
  // left: 0px;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);

  .company {
    height: 200px;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 0px 24px 0px rgba(234, 231, 255, 0.80);
    padding: 30px 0 0 20px;
    margin: 0 0px 15px 0;
    box-sizing: border-box;

    .companyImg {
      width: 80px;
      height: 80px;
      border-radius: 20px;
      margin-right: 20px;
    }

    .companyName {
      font-size: 28px;
      font-family: PingFang SC, PingFang SC-500;
      font-weight: 500;
      color: #000000;
      margin-bottom: 12px;
      text-align: left;
    }

    .tips {
      padding: 0 10px;
      height: 32px;
      background: #f8f8f8;
      border-radius: 5px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      text-align: center;
      color: #666666;
      line-height: 32px;
      margin-right: 10px;
    }

    .companyDesc {
      margin-top: 18px;
      width: 304px;
      height: 16px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      text-align: LEFT;
      color: #999999;
      line-height: 16px;
      overflow: hidden;
      /*文本超出隐藏*/
      text-overflow: ellipsis;
      /*文本超出显示省略号*/
      white-space: nowrap;
      /*超出的空白区域不换行*/
    }

    .companyDetail {
      width: 380px;
      height: 44px;
      background: #faf9fd;
      border-radius: 0px 0px 20px 20px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      text-align: center;
      color: #156bef;
      line-height: 44px;
      margin: 16px 0 0 -20px;
      cursor: pointer;
    }
  }

  //

  #wrap {
    overflow: hidden;
    position: relative;
    width: 1200px;
    height: 140px;
    white-space: nowrap;
    padding-top: 25px;

    #inner {
      position: absolute;
      animation: slide 15s linear infinite normal;
    }

    #inner:hover {
      animation: 15s slide linear infinite paused;
    }

    #first {
      display: inline-block;
    }

    #second {
      display: inline-block;
    }

    @keyframes slide {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(-50%);
      }
    }

    .otherCompany {
      height: 140px;
      border: 1px solid #fafafa;
      border-radius: 10px;
      // margin: 25px 25px 0 0;
      margin: 0 25px 0 0;
      box-sizing: border-box;
    }
  }

}

//
.videoMain {
  background: #ffffff;
  width: 100%;
  margin-top: 485px;

  .title {
    font-size: 44px;
    font-family: PingFang SC, PingFang SC-600;
    font-weight: 600;
    text-align: center;
    color: #000000;
    padding: 50px 0 40px 0;
  }

  .oneVideo {
    width: 1200px;
    height: 675px;
    border-radius: 20px;
  }

  .play {
    position: absolute;
    left: 50%;
    width: 70px;
    height: 70px;
    top: 50%;
  }

  .smallVideo {
    width: 384px;
    height: 216px;
    border-radius: 20px;
    margin-right: 24px;
  }

  .smallPlay {
    position: absolute;
    left: 39%;
    width: 70px;
    height: 70px;
    top: 32%;
  }

  .name {
    margin: 30px 0 52px 0;
    font-size: 28px;
    font-family: PingFang SC, PingFang SC-500;
    font-weight: 500;
    text-align: center;
    color: #333333;
  }
}

//
.contentMain {
  width: 100%;
  position: absolute;
  height: 1340px;
  height: 2720px;
  background: #f9fafe;

  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slideOutToLeft {
    0% {
      transform: translateX(0);
      opacity: 1;
    }

    100% {
      transform: translateX(-100%);
      opacity: 0;
    }
  }


  @keyframes slideOutToRight {
    0% {
      transform: translateX(0);
      opacity: 1;
    }

    100% {
      transform: translateX(100%);
      opacity: 0;
    }
  }

  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }


  .leftBox {
    margin: 0 352px 0 20px;
    opacity: 0;
  }

  .animate-in {
    animation-name: slideInFromLeft;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    opacity: 1;
  }

  .animate-out {
    animation-name: slideOutToLeft;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    opacity: 1;
  }

  .animate-in-right {
    animation-name: slideInFromRight;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    opacity: 1;
  }

  .animate-out-right {
    animation-name: slideOutToRight;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    opacity: 1;
  }

  .threeBox {
    opacity: 0;
    margin-left: 290px;
  }


  .name {
    margin: 50px 0 40px 0;
    font-size: 44px;
    font-family: PingFang SC, PingFang SC-600;
    font-weight: 600;
    text-align: LEFT;
    color: #000000;
  }

  .desc {
    width: 343px;
    height: 66px;
    font-size: 28px;
    font-family: PingFang SC, PingFang SC-400;
    font-weight: 400;
    text-align: LEFT;
    color: #666666;
    line-height: 35px;
  }

  .leftImg {
    width: 308px;
    height: 260px;
    margin: 50px 0px 0 0;
  }

  .rightImg {
    width: 400px;
    height: 510px;
  }
}

//
.sometimes {
  background: #ffffff;
  width: 100%;
  padding: 80px 0 73px 0;
  top: 3007px;
  height: 620px;

  .title {
    font-size: 44px;
    font-family: PingFang SC, PingFang SC-600;
    font-weight: 600;
    text-align: center;
    color: #000000;
  }

  .help {
    font-size: 28px;
    font-family: PingFang SC, PingFang SC-400;
    font-weight: 400;
    text-align: center;
    color: #999999;
    margin: 20px 0 48px 0;
  }

  .listBox {
    // margin-left: 535px;
  }

  .list {
    width: 850px;
    height: 80px;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.05);
    padding: 30px;
    box-sizing: border-box;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .name {
    font-size: 20px;
    font-family: PingFang SC, PingFang SC-500;
    font-weight: 500;
    text-align: LEFT;
    color: #333333;
  }

  .rightJt {
    width: 40px;
    height: 40px;
  }

  .showList {
    width: 592px;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-400;
    font-weight: 400;
    text-align: LEFT;
    color: #999999;
    line-height: 28px;
    padding: 30px;
    box-sizing: border-box;
  }
}

//
.dream {
  height: 245px;
  top: 3780px;
  width: 100%;
  z-index: 999;
  position: absolute;

  .earth {
    width: 120px;
    height: 120px;
    // margin: 10px 187px 0 -310px;
  }

  .name {
    width: 537px;
    height: 41px;
    margin: 102.5px 0 0 187px;
  }

  .hand {
    // position: absolute;
    // right: 642px;
    // top: 124px;
    width: 62px;
    height: 52px;
    margin: 110px 0 0 -10px;
  }

  .people {
    width: 180px;
    height: 180px;
    // position: fixed;
    // right: 360px;
    // top: 112px;
    margin: 115px 0 0 102px;
  }
}
</style>