<template>
  <div class="lastBox">
    <div class="footer">
      <div class="flex oneBox" style="">
        <div>
          <img src="@/assets/32.png" alt="" class="logo">
          <div class="one_title">快速求职，从“三才智聘” 开始<br/>直接连接企业和求职者</div>
          <div class="flex">
            <img src="@/assets/33.png" alt="" class="weixin">
            <img src="@/assets/34.png" alt="" class="weixin">
          </div>
          <div class="one_title">公司地址： 河南省洛阳市老城区青年创业大厦24层</div>
        </div>
        <div class="twoBox">
          <div class="title">导航</div>
          <div class="name" @click="navClick(0)">首页</div>
          <div class="name" @click="navClick(1)">招聘的公司</div>
          <div class="name" @click="navClick(2)">评估体系</div>
          <div class="name" @click="navClick(3)">帮助中心</div>
        </div>
        <div class="flex">
          <div class="threeBox">
            <!-- <img src="@/assets/35.png" alt="" class="wxCode"> -->
            <div class="wxCode" style="display:flex;justify-content:center;align-items:center;"><img style="width:50%" src="@/assets/zw-04.png" alt=""></div>
            <div class="name">微信小程序</div>
          </div>
          <div class="threeBox">
            <!-- <img src="@/assets/36.png" alt="" class="wxCode"> -->
            <div class="wxCode" style="display:flex;justify-content:center;align-items:center;"><img style="width:50%" src="@/assets/zw-04.png" alt=""></div>
            <div class="name">微信公众号</div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="hnpx">
        河南品讯网络科技有限公司
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pageFooter',

  data () {
    return {

    };
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.lastBox {
  position: absolute;
  top: 4970px;
  background: #000002;
  width: 100%;
  left: 0px;
  height: 440px;

  .hnpx {
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-400;
    font-weight: 400;
    text-align: center;
    color: #444444;
    padding: 66px 0 22px 0;
    background: #000002;
  }
}

.footer {
  padding: 80px 0 0 0px;
  text-align: left;
  height: 340px;

  .oneBox {
    width: 1200px;
    padding-bottom: 36px;
    border-bottom: 1px solid #1b1b1b;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }

  .logo {
    width: 150px;
    height: 50px;
    margin-bottom: 30px;
  }

  .one_title {
    width: 388px;
    height: 48px;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-400;
    font-weight: 400;
    text-align: LEFT;
    color: #ffffff;
    line-height: 28px;
    margin-bottom: 40px;
  }

  .weixin {
    width: 34px;
    height: 34px;
    margin: 0 20px 46px 0;
  }

  .twoBox {
    margin: 0 0 0 42px;
    width: 300px;
    border-left: 1px solid #1b1b1b;
    border-right: 1px solid #1b1b1b;
    padding: 0 160px 0 60px;
    box-sizing: border-box;

    .title {
      width: 36px;
      height: 18px;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-500;
      font-weight: 500;
      text-align: LEFT;
      color: #ffffff;
      line-height: 18px;
      margin-bottom: 48px;
    }

    .name {
      width: 80px;
      height: 16px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      text-align: LEFT;
      color: #ffffff;
      line-height: 16px;
      margin-bottom: 48px;
      cursor: pointer;
    }

    .name:hover {
      color: #4d3db9;
    }
  }

  .threeBox {
    .wxCode {
      width: 140px;
      height: 140px;
      margin: 50px 0 30px 50px;
      background: #fff;
    }

    .name {
      width: 80px;
      height: 16px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      line-height: 16px;
      margin-left: 80px;
    }
  }


}
</style>