<template>
  <div class="header">
    <div class="headerBox flex">
      <img src="@/assets/1.png" alt="" class="logo">
      <div class="flex">
        <div class="title " :class="navIndex == index ? 'choose_title' : ''" v-for="(item, index) in navList" :key="index"
          @click="navClick(index)">{{ item }}</div>
      </div>
      <div class="schoolBtn" @click="campusRecruitClick">
        校园招聘
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pageHeader',

  data () {
    return {
      navList: ['首页', '招聘的公司', '评估体系', '帮助中心'],
    };
  },
  props: {
    navIndex: Number,
  },

  methods: {
    navClick (index) {
      // this.navIndex = index
      this.$emit('reload');
      if (index == 1) {

        document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
        this.$router.push({
          path: '/companyView'
        })
      } else if (index == 2) {
        this.$router.push({
          path: '/estimateView'
        })
        document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
      } else if (index == 3) {
        this.$router.push({
          path: '/helpView'
        })
        document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
      } else {
        this.$router.push({
          path: '/'
        })
        document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
      }
    },
    campusRecruitClick () {
      let routeData = this.$router.resolve({ path: '/campusRecruit', });
      document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 80px;
  background: #fff;
  position: fixed;
  left: 0px;
  top: 0px;
  // padding: 0px 360px;
  line-height: 80px;
  z-index: 9999;

  .headerBox {
    max-width: 1200px;
    margin: 0 auto;
  }

  .logo {
    width: 110px;
    height: 26px;
    margin-top: 27px;
  }

  .title {
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-500;
    font-weight: 500;
    color: #000000;
    margin-left: 120px;
    cursor: pointer;
  }

  .title:hover {
    color: #4d3db9;
  }

  .choose_title {
    color: #4d3db9;
    border-bottom: 4px solid #4d3db9;
    margin-bottom: 16px;
  }

  .schoolBtn {
    width: 120px;
    height: 40px;
    background: linear-gradient(108deg, #b17bd2, #4d3db9 100%);
    border-radius: 10px;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-500;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    line-height: 40px;
    margin: 20px 0 0 240px;
    cursor: pointer;
  }
}
</style>