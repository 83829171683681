<template>
    <div class="home" id="home">
        <!-- header -->
        <page-header :navIndex="2"></page-header>
        <!-- <div class="header flex">
            <img src="../assets/1.png" alt="" class="logo">
            <div class="flex">
                <div class="title " :class="navIndex == index ? 'choose_title' : ''" v-for="(item, index) in navList"
                    :key="index" @click="navClick(index)">{{ item }}</div>
            </div>
            <div class="schoolBtn" @click="campusRecruitClick">
                校园招聘
            </div>
        </div> -->
        <!-- banner -->
        <div class="banner">
            <div class="postion_left" style="width:1200px">
                <div class="title">链接梦想 发现人才</div>
                <div class="littleTitle">三才智聘陪您一同成长</div>
            </div>
        </div>
        <!-- main -->
        <div class="main">
            <div class="main_one">


                <div class="p_relative">
                    <!--  -->
                    <div class="companyMain">
                        <!--  -->
                        <div class="flex justify-between">
                            <div class="company" v-for="(item, index) in companyList" :key="index">
                                <img :src="item.img" alt="" class="companyImg">
                                <div class="companyName">{{ item.name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--智能匹配  -->
                <div class="contentMain">
                    <div class="intelligence">
                        <div class="flex justify-between">
                            <div class="leftBox" style="text-align: left;">
                                <div class="name">评估体系</div>
                                <img src="../assets/50.png" alt="" class="radian">
                                <div class="flex ">
                                    <img src="../assets/51.png" alt="" class="tips">
                                    <div>
                                        <div class="desc">霍兰德职业测评</div>
                                        <div class="explain">了解自身职业倾向，助力您的职业发展</div>
                                    </div>
                                </div>
                                <div class="flex">
                                    <img src="../assets/51.png" alt="" class="tips">
                                    <div>
                                        <div class="desc">职业心理健康测评</div>
                                        <div class="explain">分析个人心理状态，预测职业心理健康情况</div>
                                    </div>
                                </div>
                                <div class="flex">
                                    <img src="../assets/51.png" alt="" class="tips">
                                    <div>
                                        <div class="desc">五维职业性格测评</div>
                                        <div class="explain">了解五维性格特征，提高对自我的认识</div>
                                    </div>
                                </div>
                                <div class="flex">
                                    <img src="../assets/51.png" alt="" class="tips">
                                    <div>
                                        <div class="desc">基本素质测评</div>
                                        <div class="explain">测查知觉速度与准确性，了解个人基本履职思维能力</div>
                                    </div>
                                </div>
                            </div>
                            <img src="../assets/56.png" style="margin-left: 158px;" alt="" class="rightImg">
                        </div>
                    </div>
                </div>
                <div class="flex huntBox">
                    <img src="../assets/57.png" alt="" class="huntJob">
                    <img src="../assets/58.png" alt="" class="huntJob">
                    <img src="../assets/59.png" alt="" class="huntJob">
                </div>


            </div>
        </div>
        <!-- footer -->
        <page-footer></page-footer>
        <!-- <div class="lastBox">
            <div class="footer">
                <div class="flex oneBox" style="">
                    <div>
                        <img src="../assets/32.png" alt="" class="logo">
                        <div class="one_title">快速求职，从“你好” 第三代AI人力资源匹配APP开始，直接连接企业和求职者</div>
                        <div class="flex">
                            <img src="../assets/33.png" alt="" class="weixin">
                            <img src="../assets/34.png" alt="" class="weixin">
                        </div>
                        <div class="one_title">公司地址： 河南省洛阳市老城区青年创业大厦24层</div>
                    </div>
                    <div class="twoBox">
                        <div class="title">导航</div>
                        <div class="name" @click="navClick(0)">首页</div>
                        <div class="name" @click="navClick(1)">招聘的公司</div>
                        <div class="name" @click="navClick(2)">评估体系</div>
                        <div class="name" @click="navClick(3)">帮助中心</div>
                    </div>
                    <div class="flex">
                        <div class="threeBox">
                            <img src="../assets/35.png" alt="" class="wxCode">
                            <div class="name">微信小程序</div>
                        </div>
                        <div class="threeBox">
                            <img src="../assets/36.png" alt="" class="wxCode">
                            <div class="name">微信公众号</div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="hnpx">
                    河南品讯网络科技有限公司
                </div>
            </div>
        </div> -->
    </div>
</template>
  
<script>

export default {
    inject:['reload'],
    data() {
        return {
            navIndex: 2,
            navList: ['首页', '招聘的公司', '评估体系', '帮助中心'],
            bannerList: [{
                img: require('../assets/2.png')
            }, {
                img: require('../assets/3.png')
            }],
            companyList: [{
                img: require('../assets/53.png'),
                name: '霍兰德职业测评',
            }, {
                img: require('../assets/54.png'),
                name: '职业心理健康测评',
            }, {
                img: require('../assets/55.png'),
                name: '五维职业性格测评',
            }],
            otherCompany: [
                { src: require('../assets/7.png') },
                { src: require('../assets/8.png') },
                { src: require('../assets/9.png') },
                { src: require('../assets/10.png') },
                { src: require('../assets/11.png') },
            ],
            otherCompanyTwo: [
                { src: require('../assets/12.png') },
                { src: require('../assets/13.png') },
                { src: require('../assets/14.png') },
                { src: require('../assets/15.png') },
                { src: require('../assets/16.png') },
            ],
            evaluateList: [{
                img: require('../assets/42.png'),
                name: '三才智聘非常重视体验，倡导“连接梦想，发现人才”',
            }, {
                img: require('../assets/43.png'),
                name: '我们能明显感受三才智聘对招聘服务的赋能',
            }, {
                img: require('../assets/44.png'),
                name: '三才智聘通过“数字化”解决招聘中的“痛”',
            }],
            evaluateOtherList: [{
                img: require('../assets/45.png'),
            }, {
                img: require('../assets/46.png'),
            }, {
                img: require('../assets/47.png'),
            }, {
                img: require('../assets/48.png'),
            }, {
                img: require('../assets/49.png'),
            }],
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.getLeft();
            this.getRight()
        })
        this.$forceUpdate()
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('scroll', this.handleScrollTwo);
        this.$forceUpdate()
    },
    methods: {
        getLeft() {
            //实例挂载完毕前
            var box = document.querySelector('.leftBox');
            var isVisible = false;

            this.handleScroll = function () {
                var boxPosition = box.getBoundingClientRect().top;
                var windowHeight = window.innerHeight;

                if (boxPosition < windowHeight + 300 && !isVisible) {
                    box.classList.remove('animate-out');
                    box.classList.add('animate-in');
                    isVisible = true;
                } else if (boxPosition >= windowHeight && isVisible) {
                    box.classList.remove('animate-in');
                    box.classList.add('animate-out');
                    isVisible = false;
                }
            }
            window.addEventListener('scroll', this.handleScroll);
        },
        getRight() {
            //
            var box = document.querySelector('.rightImg');
            var isVisible = false;

            this.handleScrollTwo = function () {
                var boxPosition = box.getBoundingClientRect().top;
                var windowHeight = window.innerHeight;

                if (boxPosition < windowHeight && !isVisible) {
                    box.classList.remove('animate-out-right');
                    box.classList.add('animate-in-right');
                    isVisible = true;
                } else if (boxPosition >= windowHeight && isVisible) {
                    box.classList.remove('animate-in-right');
                    box.classList.add('animate-out-right');
                    isVisible = false;
                }
            }
            window.addEventListener('scroll', this.handleScrollTwo);
        },
        navClick(index) {
            this.navIndex = index
            this.reload()
            if (index == 1) {

                document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
                this.$router.push({
                    path: '/companyView'
                })
            } else if (index == 2) {
                this.$router.push({
                    path: '/estimateView'
                })
                document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
            } else if (index == 3) {
                this.$router.push({
                    path: '/helpView'
                })
                document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
            } else {
                this.$router.push({
                    path: '/'
                })
                document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
            }
        },
        campusRecruitClick() {
            let routeData = this.$router.resolve({ path: '/campusRecruit', });
            document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
            window.open(routeData.href, '_blank');
        },
        questionClick(index) {
            this.questionIndex = index
        },
        questionNoClick() {
            this.questionIndex = -1
        },

    }
}
</script>
<style lang="scss" scoped>
.home {
    position: relative;
    z-index: 99;
}

// .header {
//     width: 100%;
//     height: 80px;
//     background: #fff;
//     position: fixed;
//     left: 0px;
//     top: 0px;
//     padding: 0px 360px;
//     line-height: 80px;
//     z-index: 9999;

//     .logo {
//         width: 110px;
//         height: 26px;
//         margin-top: 27px;
//     }

//     .title {
//         font-size: 16px;
//         font-family: PingFang SC, PingFang SC-500;
//         font-weight: 500;
//         color: #000000;
//         margin-left: 120px;
//         cursor: pointer;
//     }

//     .title:hover {
//         color: #4d3db9;
//     }

//     .choose_title {
//         color: #4d3db9;
//         border-bottom: 4px solid #4d3db9;
//         margin-bottom: 16px;
//     }

//     .schoolBtn {
//         width: 120px;
//         height: 40px;
//         background: linear-gradient(108deg, #b17bd2, #4d3db9 100%);
//         border-radius: 10px;
//         font-size: 16px;
//         font-family: PingFang SC, PingFang SC-500;
//         font-weight: 500;
//         text-align: center;
//         color: #ffffff;
//         line-height: 40px;
//         margin: 20px 0 0 240px;
//         cursor: pointer;
//     }
// }

//
.banner {
    position: absolute;
    left: 0px;
    top: 80px;
    width: 100%;
    height: 640px;
    max-height: 640px;
    background-image: url('../assets/60.png');

    .title {
        font-size: 70px;
        font-family: PingFang SC, PingFang SC-600;
        font-weight: 600;
        text-align: center;
        color: #333333;
        line-height: 70px;
        margin: 240px 0 0 0;
    }

    .littleTitle {
        margin: 30px 0 0 0;
        font-size: 28px;
        font-family: PingFang SC, PingFang SC-400;
        font-weight: 400;
        text-align: center;
        color: #333333;
    }
}

//
.main {
    position: absolute;
    left: 0px;
    top: 740px;
    right: 0px;
    background: #f9fafe;
}

.main_one {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 1200px;
}

//
.companyMain {
    width: 1200px;
    margin: 0 0px 50px 0px;
    position: absolute;
    left: 0px;
    top: -100px;

    .company {
        height: 300px;
        background: #ffffff;
        border-radius: 20px;
        box-shadow: 0px 0px 24px 0px rgba(234, 231, 255, 0.80);
        padding: 10px 10px 0px 10px;
        margin: 0 0px 15px 0;
        box-sizing: border-box;

        .companyImg {
            width: 360px;
            height: 200px;
            border-radius: 10px 10px 0px 0px;
        }

        .companyName {
            font-size: 28px;
            font-family: PingFang SC, PingFang SC-500;
            font-weight: 500;
            color: #333333;
            margin-top: 30px;
            text-align: center;
        }
    }
}


//
.contentMain {
    margin-top: 280px;

    .intelligence {
        .name {
            font-size: 44px;
            font-family: PingFang SC, PingFang SC-600;
            font-weight: 600;
            text-align: LEFT;
            color: #000000;
        }

        .radian {
            width: 136px;
            height: 12px;
            margin: -4px 0px 45px 17px;
        }

        .tips {
            width: 34px;
            height: 34px;
            margin: 7px 20px 0 0;
        }

        .desc {
            font-size: 28px;
            font-family: PingFang SC, PingFang SC-400;
            font-weight: 400;
            text-align: LEFT;
            color: #666666;
            margin: 0 0 0 0;
        }

        .explain {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-400;
            font-weight: 400;
            text-align: LEFT;
            color: #666666;
            margin: 15px 0 40px 0;
        }
    }


    .rightImg {
        width: 540px;
        height: 540px;
    }

    @keyframes slideInFromLeft {
        0% {
            transform: translateX(-100%);
            opacity: 0;
        }

        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes slideOutToLeft {
        0% {
            transform: translateX(0);
            opacity: 1;
        }

        100% {
            transform: translateX(-100%);
            opacity: 0;
        }
    }

    @keyframes slideInFromRight {
        0% {
            transform: translateX(100%);
            opacity: 0;
        }

        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }



    .animate-in {
        animation-name: slideInFromLeft;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        opacity: 1;
    }

    .animate-out {
        animation-name: slideOutToLeft;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        opacity: 1;
    }

    .animate-in-right {
        animation-name: slideInFromRight;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        opacity: 1;
    }

    .animate-out-right {
        animation-name: slideOutToRight;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        opacity: 1;
    }


}

//
.huntBox {
    margin: 75px 0 80px 0px;

    .huntJob {
        width: 380px;
        height: 160px;
        margin-right: 30px;
    }
}


//
.lastBox {
    position: absolute;
    top: 1920px;
    background: #000002;
    width: 100%;
    left: 0px;
    height: 440px;

    .hnpx {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-400;
        font-weight: 400;
        text-align: center;
        color: #444444;
        padding: 66px 0 22px 0;
        background: #000002;
    }
}

.footer {
    padding: 80px 0 0 0px;
    text-align: left;
    height: 340px;

    .oneBox {
        width: 1200px;
        padding-bottom: 36px;
        border-bottom: 1px solid #1b1b1b;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
    }

    .logo {
        width: 150px;
        height: 50px;
        margin-bottom: 30px;
    }

    .one_title {
        width: 388px;
        height: 48px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-400;
        font-weight: 400;
        text-align: LEFT;
        color: #ffffff;
        line-height: 28px;
        margin-bottom: 40px;
    }

    .weixin {
        width: 34px;
        height: 34px;
        margin: 0 20px 46px 0;
    }

    .twoBox {
        margin: 0 0 0 42px;
        width: 300px;
        border-left: 1px solid #1b1b1b;
        border-right: 1px solid #1b1b1b;
        padding: 0 160px 0 60px;
        box-sizing: border-box;

        .title {
            width: 36px;
            height: 18px;
            font-size: 18px;
            font-family: PingFang SC, PingFang SC-500;
            font-weight: 500;
            text-align: LEFT;
            color: #ffffff;
            line-height: 18px;
            margin-bottom: 48px;
        }

        .name {
            width: 80px;
            height: 16px;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-400;
            font-weight: 400;
            text-align: LEFT;
            color: #ffffff;
            line-height: 16px;
            margin-bottom: 48px;
            cursor: pointer;
        }

        .name:hover {
            color: #4d3db9;
        }
    }

    .threeBox {
        .wxCode {
            width: 140px;
            height: 140px;
            margin: 50px 0 30px 50px;
        }

        .name {
            width: 80px;
            height: 16px;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-400;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            line-height: 16px;
            margin-left: 80px;
        }
    }


}
</style>