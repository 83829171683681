<template>
    <div class="home" id="home">
        <div class="header flex">
            <img src="../assets/1.png" alt="" class="logo" @click="indexClick" style="cursor: pointer;">
            <img src="../assets/91.png" alt="" class="logo">
        </div>
        <!-- banner -->
        <div class="banner">
            <div class="title">学生身份有保障，企业认证更可信</div>
            <div class="littleTitle">三才智聘陪您携手校园招聘，开启职业新篇章</div>
        </div>
        <!--  -->
        <div class="postion_left" style="width:1200px">
            <div class="enterprise">
                <div class="p_relative postion_left" style="width:1200px">
                    <div class="title">校招企业</div>
                    <!--  -->
                    <div class="companyMain">
                        <div class="flex-wrap justify-between">
                            <div class="company p_relative" v-for="(item, index) in companyList" :key="index">
                                <div class="authentication">校园认证</div>
                                <div class="flex">
                                    <img :src="item.img" alt="" class="companyImg">
                                    <div>
                                        <div class="companyName">{{ item.name }}</div>
                                        <div class="flex">
                                            <div class="tips">{{ item.career }}</div>
                                            <div class="tips">{{ item.num }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="companyDesc">{{ item.desc }}</div>
                                <div class="companyDetail" @click="lookDetail">查看详情></div>
                            </div>
                        </div>
                    </div>
                    <div class="title">合作校园</div>
                    <!--  -->
                    <div class="school">
                        <div class="flex-wrap justify-between">
                            <div v-for="(item, index) in schoolList" :key="index" style="margin: 30px 45px  0 45px ; ">
                                <img :src="item.src" alt="" class="schoolImage">
                                <div class="schoolName">{{ item.name }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="title">校招专场</div>
                    <!--  -->
                    <div class="special">
                        <div class="flex-wrap">
                            <div v-for="(item, index) in specialList" :key="index" class="specialBox">
                                <img :src="item.src" alt="" class="specialImage">
                                <div class="specialName">{{ item.name }}</div>
                                <div class="specialAdress flex items-center">
                                    <img src="../assets/89.png" alt="" class="adressIcon">
                                    {{ item.adress }}
                                </div>
                                <div class="flex justify-between items-center">
                                    <div class="specialAdress flex items-center">
                                        <img src="../assets/90.png" alt="" class="adressIcon">
                                        {{ item.time }}
                                    </div>
                                    <div class="speak">预告</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="title" style="margin-top: 94px;">求职指导</div>
                    <div class="flex">
                        <div class="wages">
                            <div class="wagesName">工资计算器</div>
                            <div class="wagesLast">计算你的税后工资</div>
                        </div>
                        <div class="test">
                            <div class="wagesName">测评工具</div>
                            <div class="wagesLast">内含职业倾向与心理健康等多方面的测评工具</div>
                        </div>
                    </div>
                    <!--  -->
                    <div class="flex huntBox">
                        <img src="../assets/57.png" alt="" class="huntJob">
                        <img src="../assets/58.png" alt="" class="huntJob">
                        <img src="../assets/59.png" alt="" class="huntJob">
                    </div>
                </div>
            </div>
        </div>
        <!-- footer -->
        <page-footer></page-footer>
        <!-- <div class="lastBox">
            <div class="footer">
                <div class="flex oneBox" style="">
                    <div>
                        <img src="../assets/32.png" alt="" class="logo">
                        <div class="one_title">快速求职，从“你好” 第三代AI人力资源匹配APP开始，直接连接企业和求职者</div>
                        <div class="flex">
                            <img src="../assets/33.png" alt="" class="weixin">
                            <img src="../assets/34.png" alt="" class="weixin">
                        </div>
                        <div class="one_title">公司地址： 河南省洛阳市老城区青年创业大厦24层</div>
                    </div>
                    <div class="twoBox">
                        <div class="title">导航</div>
                        <div class="name" @click="navClick(0)">首页</div>
                        <div class="name" @click="navClick(1)">招聘的公司</div>
                        <div class="name" @click="navClick(2)">评估体系</div>
                        <div class="name" @click="navClick(3)">帮助中心</div>
                    </div>
                    <div class="flex">
                        <div class="threeBox">
                            <img src="../assets/35.png" alt="" class="wxCode">
                            <div class="name">微信小程序</div>
                        </div>
                        <div class="threeBox">
                            <img src="../assets/36.png" alt="" class="wxCode">
                            <div class="name">微信公众号</div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="hnpx">
                    河南品讯网络科技有限公司
                </div>
            </div>
        </div> -->
    </div>
</template>
<script>
import { ElMessage } from 'element-plus'
export default {
    data() {
        return {
            // companyList: [{
            //     img: require('../assets/4.png'),
            //     name: '中国联通',
            //     career: '互联网',
            //     num: '200人以上',
            //     desc: '五险一金，定期体检，加班补助，年终奖年终奖'
            // }, {
            //     img: require('../assets/5.png'),
            //     name: '平安金服',
            //     career: '互联网',
            //     num: '200人以上',
            //     desc: '五险一金，定期体检，加班补助，年终奖年终奖'
            // }, {
            //     img: require('../assets/6.png'),
            //     name: '华为',
            //     career: '互联网',
            //     num: '200人以上',
            //     desc: '五险一金，定期体检，加班补助，年终奖年终奖'
            // }, {
            //     img: require('../assets/86.png'),
            //     name: '京东集团',
            //     career: '互联网',
            //     num: '200人以上',
            //     desc: '五险一金，定期体检，加班补助，年终奖年终奖'
            // }, {
            //     img: require('../assets/87.png'),
            //     name: '网易集团',
            //     career: '互联网',
            //     num: '200人以上',
            //     desc: '五险一金，定期体检，加班补助，年终奖年终奖'
            // }, {
            //     img: require('../assets/88.png'),
            //     name: '百度',
            //     career: '互联网',
            //     num: '200人以上',
            //     desc: '五险一金，定期体检，加班补助，年终奖年终奖'
            // }],
            companyList: [{
                img: require('../assets/zw-02.png'),
                name: '在洛阳',
                career: '互联网',
                num: '200人以上',
                desc: '五险一金，定期体检，加班补助，年终奖年终奖'
            }, {
                img: require('../assets/zw-02.png'),
                name: '在洛阳',
                career: '互联网',
                num: '200人以上',
                desc: '五险一金，定期体检，加班补助，年终奖年终奖'
            }, {
                img: require('../assets/zw-02.png'),
                name: '在洛阳',
                career: '互联网',
                num: '200人以上',
                desc: '五险一金，定期体检，加班补助，年终奖年终奖'
            }, {
                img: require('../assets/zw-02.png'),
                name: '在洛阳',
                career: '互联网',
                num: '200人以上',
                desc: '五险一金，定期体检，加班补助，年终奖年终奖'
            }, {
                img: require('../assets/zw-02.png'),
                name: '在洛阳',
                career: '互联网',
                num: '200人以上',
                desc: '五险一金，定期体检，加班补助，年终奖年终奖'
            }, {
                img: require('../assets/zw-02.png'),
                name: '在洛阳',
                career: '互联网',
                num: '200人以上',
                desc: '五险一金，定期体检，加班补助，年终奖年终奖'
            }],
            // schoolList: [{
            //     src: require('../assets/66.png'),
            //     name: '洛阳师范学院'
            // }, {
            //     src: require('../assets/67.png'),
            //     name: '洛阳理工学院'
            // }, {
            //     src: require('../assets/68.png'),
            //     name: '河南科技大学'
            // }, {
            //     src: require('../assets/69.png'),
            //     name: '河南师范大学'
            // }, {
            //     src: require('../assets/70.png'),
            //     name: '南阳师范学院'
            // }, {
            //     src: require('../assets/71.png'),
            //     name: '信阳师范学院'
            // }, {
            //     src: require('../assets/72.png'),
            //     name: '商丘师范学院'
            // }, {
            //     src: require('../assets/73.png'),
            //     name: '南阳理工学院'
            // }, {
            //     src: require('../assets/74.png'),
            //     name: '周口师范学院'
            // }, {
            //     src: require('../assets/75.png'),
            //     name: '许昌学院'
            // }],

            schoolList: [{
                src: require('../assets/66.png'),
                name: '洛阳师范学院'
            }, {
                src: require('../assets/66.png'),
                name: '洛阳师范学院'
            }, {
                src: require('../assets/66.png'),
                name: '洛阳师范学院'
            }, {
                src: require('../assets/66.png'),
                name: '洛阳师范学院'
            }, {
                src: require('../assets/66.png'),
                name: '洛阳师范学院'
            }, {
                src: require('../assets/66.png'),
                name: '洛阳师范学院'
            }, {
                src: require('../assets/66.png'),
                name: '洛阳师范学院'
            }, {
                src: require('../assets/66.png'),
                name: '洛阳师范学院'
            }, {
                src: require('../assets/66.png'),
                name: '洛阳师范学院'
            }, {
                src: require('../assets/66.png'),
                name: '洛阳师范学院'
            }],
            specialList: [{
                name: '校园招聘专场',
                adress: '洛阳师范学院逸夫楼',
                time: '2023/12/14日',
                src: require('../assets/76.png'),
            }, {
                name: '校园招聘专场',
                adress: '洛阳师范学院逸夫楼',
                time: '2023/12/14日',
                src: require('../assets/77.png'),
            }, {
                name: '校园招聘专场',
                adress: '洛阳师范学院逸夫楼',
                time: '2023/12/14日',
                src: require('../assets/78.png'),
            }, {
                name: '校园招聘专场',
                adress: '洛阳师范学院逸夫楼',
                time: '2023/12/14日',
                src: require('../assets/79.png'),
            }, {
                name: '校园招聘专场',
                adress: '洛阳师范学院逸夫楼',
                time: '2023/12/14日',
                src: require('../assets/80.png'),
            }, {
                name: '校园招聘专场',
                adress: '洛阳师范学院逸夫楼',
                time: '2023/12/14日',
                src: require('../assets/81.png'),
            }, {
                name: '校园招聘专场',
                adress: '洛阳师范学院逸夫楼',
                time: '2023/12/14日',
                src: require('../assets/82.png'),
            }, {
                name: '校园招聘专场',
                adress: '洛阳师范学院逸夫楼',
                time: '2023/12/14日',
                src: require('../assets/83.png'),
            }],
        }
    },
    methods: {
        lookDetail() {
            ElMessage({
                message: '正在建设中，敬请期待......',
                type: 'warning',
                offset: 100,
                customClass: 'messageIndex'
            })
        },
        indexClick() {
            this.$router.push({ path: '/' })
            document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
        }
    }
}
</script>
<style lang="scss" scoped>
.home {
    position: relative;
    z-index: 99;
}

.header {
    height: 80px;
    background: #ffffff;
    padding: 27px 0 27px 360px;
    box-sizing: border-box;

    .logo {
        height: 22px;
        margin-right: 20px;
    }
}

.banner {
    position: absolute;
    left: 0px;
    top: 80px;
    width: 100%;
    height: 680px;
    background-image: url('../assets/65.png');

    .title {
        font-size: 70px;
        font-family: PingFang SC, PingFang SC-600;
        font-weight: 600;
        text-align: center;
        color: #333333;
        line-height: 70px;
        margin: 240px 0 0 0;
    }

    .littleTitle {
        margin: 30px 0 292px 0;
        font-size: 28px;
        font-family: PingFang SC, PingFang SC-400;
        font-weight: 400;
        text-align: center;
        color: #333333;
    }
}

.enterprise {
    width: 100%;
    background: #f9fafe;
    position: absolute;
    top: 630px;
    left: 0px;

    .title {
        margin: 60px 0 30px 0;
        text-align: center;
        font-size: 44px;
        font-family: PingFang SC, PingFang SC-600;
        font-weight: 600;
        color: #000000;
        text-align: center;
        width: 1200px;
    }

    .companyMain {
        width: 1200px;
        margin: 0 0px 50px 0px;
        // transform: translateX(-50%);
        // position: absolute;
        // left: 50%;

        .company {
            .authentication {
                width: 80px;
                height: 30px;
                background: #e9f8f8;
                font-size: 14px;
                font-family: PingFang SC, PingFang SC-400;
                font-weight: 400;
                text-align: center;
                color: #4ba6a8;
                line-height: 30px;
                border-radius: 0 20px 0 20px;
                position: absolute;
                right: 0px;
                top: 0px;
            }

            height: 200px;
            background: #ffffff;
            border-radius: 20px;
            box-shadow: 0px 0px 24px 0px rgba(234, 231, 255, 0.80);
            padding: 30px 0 0 20px;
            margin: 30px 0px 0px 0;
            box-sizing: border-box;

            .companyImg {
                width: 80px;
                height: 80px;
                border-radius: 20px;
                margin-right: 20px;
            }

            .companyName {
                font-size: 28px;
                font-family: PingFang SC, PingFang SC-500;
                font-weight: 500;
                color: #000000;
                margin-bottom: 12px;
                text-align: left;
            }

            .tips {
                padding: 0 10px;
                height: 32px;
                background: #f8f8f8;
                border-radius: 5px;
                font-size: 16px;
                font-family: PingFang SC, PingFang SC-400;
                font-weight: 400;
                text-align: center;
                color: #666666;
                line-height: 32px;
                margin-right: 10px;
            }

            .companyDesc {
                margin-top: 18px;
                width: 304px;
                height: 16px;
                font-size: 16px;
                font-family: PingFang SC, PingFang SC-400;
                font-weight: 400;
                text-align: LEFT;
                color: #999999;
                line-height: 16px;
                overflow: hidden;
                /*文本超出隐藏*/
                text-overflow: ellipsis;
                /*文本超出显示省略号*/
                white-space: nowrap;
                /*超出的空白区域不换行*/
            }

            .companyDetail {
                width: 380px;
                height: 44px;
                background: #fff;
                border-top: 1px solid #f6f6f6;
                border-radius: 0px 0px 20px 20px;
                font-size: 16px;
                font-family: PingFang SC, PingFang SC-400;
                font-weight: 400;
                text-align: center;
                color: #156bef;
                line-height: 44px;
                margin: 16px 0 0 -20px;
                cursor: pointer;
            }
        }

    }

    .school {
        width: 1200px;
        margin: 0 0px 50px 0px;

        .schoolImage {
            width: 150px;
            height: 150px;
        }

        .schoolName {
            font-size: 24px;
            font-family: PingFang SC, PingFang SC-500;
            font-weight: 500;
            text-align: center;
            color: #333333;
            margin: 0px 0 20px 0;
        }
    }

    .special {
        width: 1200px;
        margin: 0 0px 50px 0px;

        .specialBox {
            margin: 30px 15px -10px 0px;
            width: 285px;
            height: 280px;
            background: #ffffff;
            border-radius: 20px;
            box-sizing: border-box;
            padding: 0 20px 0 0;
        }

        .specialImage {
            width: 285px;
            height: 160px;
            border-radius: 20px 20px 0px 0px;
        }

        .specialName {
            font-size: 24px;
            font-family: PingFang SC, PingFang SC-500;
            font-weight: 500;
            text-align: LEFT;
            color: #333333;
            padding: 8px 0 0px 15px;
        }

        .adressIcon {
            width: 13px;
            height: 13px;
            margin: 0 6px 0 16px;

        }

        .specialAdress {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-400;
            font-weight: 400;
            text-align: LEFT;
            color: #666666;
            margin: 9px 0 0 6px;
        }

        .speak {
            width: 60px;
            height: 24px;
            background: #fdf2ee;
            border-radius: 5px;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-400;
            font-weight: 400;
            text-align: center;
            color: #fd8050;
            line-height: 24px;
        }
    }

    //
    .wages {
        width: 585px;
        height: 180px;
        border-radius: 20px;
        background-image: url('../assets/84.png');
        margin: 30px 0 0 0px;

        .wagesName {
            font-size: 28px;
            font-family: PingFang SC, PingFang SC-500;
            font-weight: 500;
            color: #4486ff;
            text-align: LEFT;
            margin: 60px 0 15px 35px;
        }

        .wagesLast {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-400;
            font-weight: 400;
            text-align: LEFT;
            margin: 0 0 0 35px;
            color: #666666;
        }
    }

    .test {
        width: 585px;
        height: 180px;
        border-radius: 20px;
        background-image: url('../assets/85.png');
        margin: 30px 0 0 30px;

        .wagesName {
            font-size: 28px;
            font-family: PingFang SC, PingFang SC-500;
            font-weight: 500;
            color: #4486ff;
            text-align: LEFT;
            margin: 60px 0 15px 35px;
        }

        .wagesLast {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-400;
            font-weight: 400;
            text-align: LEFT;
            margin: 0 0 0 35px;
            color: #666666;
        }
    }

    //
    .huntBox {
        margin: 30px 0 86px 0px;

        .huntJob {
            width: 380px;
            height: 160px;
            margin-right: 30px;
        }
    }
}

////
.lastBox {
    position: absolute;
    top: 3380px;
    background: #000002;
    width: 100%;
    left: 0px;
    height: 440px;

    .hnpx {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-400;
        font-weight: 400;
        text-align: center;
        color: #444444;
        padding: 66px 0 22px 0;
        background: #000002;
    }
}

.footer {
    padding: 80px 0 0 0px;
    text-align: left;
    height: 340px;

    .oneBox {
        width: 1200px;
        padding-bottom: 36px;
        border-bottom: 1px solid #1b1b1b;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
    }

    .logo {
        width: 150px;
        height: 50px;
        margin-bottom: 30px;
        cursor: pointer;
    }

    .one_title {
        width: 388px;
        height: 48px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-400;
        font-weight: 400;
        text-align: LEFT;
        color: #ffffff;
        line-height: 28px;
        margin-bottom: 40px;
    }

    .weixin {
        width: 34px;
        height: 34px;
        margin: 0 20px 46px 0;
    }

    .twoBox {
        margin: 0 0 0 42px;
        width: 300px;
        border-left: 1px solid #1b1b1b;
        border-right: 1px solid #1b1b1b;
        padding: 0 160px 0 60px;
        box-sizing: border-box;

        .title {
            width: 36px;
            height: 18px;
            font-size: 18px;
            font-family: PingFang SC, PingFang SC-500;
            font-weight: 500;
            text-align: LEFT;
            color: #ffffff;
            line-height: 18px;
            margin-bottom: 48px;
        }

        .name {
            width: 80px;
            height: 16px;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-400;
            font-weight: 400;
            text-align: LEFT;
            color: #ffffff;
            line-height: 16px;
            margin-bottom: 48px;
            cursor: pointer;
        }

        .name:hover {
            color: #4d3db9;
        }
    }

    .threeBox {
        .wxCode {
            width: 140px;
            height: 140px;
            margin: 50px 0 30px 50px;
        }

        .name {
            width: 80px;
            height: 16px;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-400;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            line-height: 16px;
            margin-left: 80px;
        }
    }


}
</style>