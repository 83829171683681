import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import companyView from '../views/companyView.vue'
import estimateView from '../views/estimateView.vue'
import helpView from '../views/helpView.vue'
import campusRecruit from '../views/campusRecruit.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/companyView',
    name: 'companyView',
    component: companyView,
  },
  {
    path: '/estimateView',
    name: 'estimateView',
    component: estimateView
  },
  {
    path: '/helpView',
    name: 'helpView',
    component: helpView
  },
  {
    path: '/campusRecruit',
    name: 'campusRecruit',
    component: campusRecruit
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
