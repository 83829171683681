import { createApp } from 'vue'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import pageFooter from './components/pageFooter/index.vue'
import pageHeader from './components/pageHeader/index.vue'

import App from './App.vue'
import router from './router'
import store from './store'
createApp(App).use(ElementPlus).use(store).use(router).component('page-footer',pageFooter).component('page-header',pageHeader).mount('#app')
