<template>
  <router-view v-if="isReload" />
</template>
<script>
export default {
  data () {
    return {
      isReload: true
    }
  },
  provide () {
    return {
      reload: this.reload,
    };
  },
  methods: {
    reload () {
      console.log('刷新了');
      this.isReload = false
      this.$nextTick(() => {
        this.isReload = true
      })
    }
  }
}
</script>
<style lang="scss">
html,
body,
div,
p,
ul,
li {
  margin: 0;
  padding: 0;
}

.messageIndex {
  z-index: 9999 !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.p_relative {
  position: relative;
}

.postion_left {
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
}

.flex {
  display: flex;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-baseline {
  align-self: baseline;
}

.self-stretch {
  align-self: stretch;
}</style>
