<template>
    <div class="home" id="home">
        <!-- header -->
        <page-header :navIndex="3"></page-header>
        <!-- <div class="header flex">
            <img src="../assets/1.png" alt="" class="logo">
            <div class="flex">
                <div class="title " :class="navIndex == index ? 'choose_title' : ''" v-for="(item, index) in navList"
                    :key="index" @click="navClick(index)">{{ item }}</div>
            </div>
            <div class="schoolBtn" @click="campusRecruitClick">
                校园招聘
            </div>
        </div> -->
        <!-- banner -->
        <div class="banner">
            <div class="postion_left" style="width:1200px">
                <div class="title">找工作不再迷茫！</div>
                <div class="littleTitle">疑问解答、技术支持，三才智聘陪您一同成长</div>
                <div class=" p_relative">
                    <div class=" flex postion_left">
                        <input type="text" placeholder="请输入您的问题" class="inputVal">
                        <div class="search" @click="search">搜索</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- main -->
        <div class="main">
            <div class="p_relative">
                <!--  -->
                <div class="companyMain postion_left">
                    <!--  -->
                    <div class="flex justify-between">
                        <div class="create" v-for="(item, index) in createList" :key="index">
                            <div class="title">{{ item.name }}</div>
                            <div class="flex items-center" v-for="(item1, index1) in item.list" :key="index1">
                                <img src="../assets/62.png" alt="" class="image">
                                <div class="name">{{ item1 }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- footer -->
        <page-footer></page-footer>
        <!-- <div class="lastBox">
            <div class="footer">
                <div class="flex oneBox" style="">
                    <div>
                        <img src="../assets/32.png" alt="" class="logo">
                        <div class="one_title">快速求职，从“你好” 第三代AI人力资源匹配APP开始，直接连接企业和求职者</div>
                        <div class="flex">
                            <img src="../assets/33.png" alt="" class="weixin">
                            <img src="../assets/34.png" alt="" class="weixin">
                        </div>
                        <div class="one_title">公司地址： 河南省洛阳市老城区青年创业大厦24层</div>
                    </div>
                    <div class="twoBox">
                        <div class="title">导航</div>
                        <div class="name" @click="navClick(0)">首页</div>
                        <div class="name" @click="navClick(1)">招聘的公司</div>
                        <div class="name" @click="navClick(2)">评估体系</div>
                        <div class="name" @click="navClick(3)">帮助中心</div>
                    </div>
                    <div class="flex">
                        <div class="threeBox">
                            <img src="../assets/35.png" alt="" class="wxCode">
                            <div class="name">微信小程序</div>
                        </div>
                        <div class="threeBox">
                            <img src="../assets/36.png" alt="" class="wxCode">
                            <div class="name">微信公众号</div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="hnpx">
                    河南品讯网络科技有限公司
                </div>
            </div>
        </div> -->
    </div>
</template>
  
<script>
import { ElMessage } from 'element-plus'
export default {
    inject: ['reload'],
    data() {
        return {
            navIndex: 3,
            navList: ['首页', '招聘的公司', '评估体系', '帮助中心'],
            createList: [{
                name: '创建账号',
                list: ['创建账号', '企业认证', '如何邀请伙伴加入平台', '如何编辑公司信息', '如何获取人才', '公司招聘技巧', '如何添加/编辑信息', '多家企业招聘管理']
            }, {
                name: '招聘启事',
                list: ['招聘管理', '如何停止推荐', '利用测评筛选人才', '使用人工智能创建职位描述', '获取优秀企业信息', '二维码分享企业',]
            }, {
                name: '智能推荐',
                list: ['智能推荐职位', '智能推荐人才', '企业分析', '人才分析']
            }]
        }
    },
    mounted() {
        this.$forceUpdate()
    },
    methods: {
        search() {
            ElMessage({
                message: '正在建设中，敬请期待......',
                type: 'warning',
                offset: 100,
                customClass: 'messageIndex'
            })
        },
        navClick(index) {
            this.navIndex = index
            this.reload()
            if (index == 1) {

                document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
                this.$router.push({
                    path: '/companyView'
                })
            } else if (index == 2) {
                this.$router.push({
                    path: '/estimateView'
                })
                document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
            } else if (index == 3) {
                this.$router.push({
                    path: '/helpView'
                })
                document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
            } else {
                this.$router.push({
                    path: '/'
                })
                document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
            }
        },
        campusRecruitClick() {
            let routeData = this.$router.resolve({ path: '/campusRecruit', });
            document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
            window.open(routeData.href, '_blank');
        },
    }
}
</script>
<style lang="scss" scoped>
.home {
    position: relative;
    z-index: 99;
}

// .header {
//     width: 100%;
//     height: 80px;
//     background: #fff;
//     position: fixed;
//     left: 0px;
//     top: 0px;
//     padding: 0px 360px;
//     line-height: 80px;
//     z-index: 9999;

//     .logo {
//         width: 110px;
//         height: 26px;
//         margin-top: 27px;
//     }

//     .title {
//         font-size: 16px;
//         font-family: PingFang SC, PingFang SC-500;
//         font-weight: 500;
//         color: #000000;
//         margin-left: 120px;
//         cursor: pointer;
//     }

//     .title:hover {
//         color: #4d3db9;
//     }

//     .choose_title {
//         color: #4d3db9;
//         border-bottom: 4px solid #4d3db9;
//         margin-bottom: 16px;
//     }

//     .schoolBtn {
//         width: 120px;
//         height: 40px;
//         background: linear-gradient(108deg, #b17bd2, #4d3db9 100%);
//         border-radius: 10px;
//         font-size: 16px;
//         font-family: PingFang SC, PingFang SC-500;
//         font-weight: 500;
//         text-align: center;
//         color: #ffffff;
//         line-height: 40px;
//         margin: 20px 0 0 240px;
//         cursor: pointer;
//     }
// }

//
.banner {
    position: absolute;
    left: 0px;
    top: 80px;
    width: 100%;
    height: 640px;
    background-image: url('../assets/61.png');

    .title {
        font-size: 70px;
        font-family: PingFang SC, PingFang SC-600;
        font-weight: 600;
        text-align: center;
        color: #333333;
        line-height: 70px;
        margin: 160px 0 0 0;
    }

    .littleTitle {
        margin: 30px 0 50px 0;
        font-size: 28px;
        font-family: PingFang SC, PingFang SC-400;
        font-weight: 400;
        text-align: center;
        color: #333333;
    }

    .inputVal {
        width: 640px;
        background: #ffffff;
        border-radius: 10px;
        padding: 22px 20px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-400;
        font-weight: 400;
        text-align: LEFT;
        color: #333;
        // margin-left: 640px;
        box-sizing: border-box;
        height: 60px;
    }

    input {
        outline: none;
        border: 0;
    }

    .search {
        width: 100px;
        height: 50px;
        background: #4d3db9;
        border-radius: 10px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-500;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 50px;
        margin: 5px 0 0px -110px;
        cursor: pointer;
    }
}

//
.main {
    position: absolute;
    left: 0px;
    top: 752px;
    right: 0px;
    background: #f9fafe;
}

//
.companyMain {
    width: 1200px;
    margin: 0 0px 60px 0px;
    position: absolute;
    top: -100px;
    padding: 30px;
    background-color: #ffff;
    border-radius: 20px;

    .create {
        width: 330px;
    }

    .title {
        font-size: 20px;
        font-family: PingFang SC, PingFang SC-500;
        font-weight: 500;
        text-align: LEFT;
        color: #333333;
        padding-bottom: 30px;
        border-bottom: 1px solid #dedede;
    }

    .image {
        width: 14px;
        height: 14px;
        margin: 31px 12px 0 0;
    }

    .name {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-400;
        font-weight: 400;
        text-align: LEFT;
        color: #455465;
        margin: 31px 0 0 0;
    }
}

////
.lastBox {
    position: absolute;
    top: 1260px;
    background: #000002;
    width: 100%;
    left: 0px;
    height: 440px;

    .hnpx {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-400;
        font-weight: 400;
        text-align: center;
        color: #444444;
        padding: 66px 0 22px 0;
        background: #000002;
    }
}

.footer {
    padding: 80px 0 0 0px;
    text-align: left;
    height: 340px;

    .oneBox {
        width: 1200px;
        padding-bottom: 36px;
        border-bottom: 1px solid #1b1b1b;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
    }

    .logo {
        width: 150px;
        height: 50px;
        margin-bottom: 30px;
    }

    .one_title {
        width: 388px;
        height: 48px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-400;
        font-weight: 400;
        text-align: LEFT;
        color: #ffffff;
        line-height: 28px;
        margin-bottom: 40px;
    }

    .weixin {
        width: 34px;
        height: 34px;
        margin: 0 20px 46px 0;
    }

    .twoBox {
        margin: 0 0 0 42px;
        width: 300px;
        border-left: 1px solid #1b1b1b;
        border-right: 1px solid #1b1b1b;
        padding: 0 160px 0 60px;
        box-sizing: border-box;

        .title {
            width: 36px;
            height: 18px;
            font-size: 18px;
            font-family: PingFang SC, PingFang SC-500;
            font-weight: 500;
            text-align: LEFT;
            color: #ffffff;
            line-height: 18px;
            margin-bottom: 48px;
        }

        .name {
            width: 80px;
            height: 16px;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-400;
            font-weight: 400;
            text-align: LEFT;
            color: #ffffff;
            line-height: 16px;
            margin-bottom: 48px;
            cursor: pointer;
        }

        .name:hover {
            color: #4d3db9;
        }
    }

    .threeBox {
        .wxCode {
            width: 140px;
            height: 140px;
            margin: 50px 0 30px 50px;
        }

        .name {
            width: 80px;
            height: 16px;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-400;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            line-height: 16px;
            margin-left: 80px;
        }
    }


}



</style>