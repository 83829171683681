<template>
  <div class="home" id="home">
    <!-- header -->
    <page-header :navIndex="1"></page-header>
    <!-- <div class="header flex">
      <img src="../assets/1.png" alt="" class="logo">
      <div class="flex">
        <div class="title " :class="navIndex == index ? 'choose_title' : ''" v-for="(item, index) in navList" :key="index"
          @click="navClick(index)">{{ item }}</div>
      </div>
      <div class="schoolBtn" @click="campusRecruitClick">
        校园招聘
      </div>
    </div> -->
    <!-- banner -->
    <div class="banner">
      <div class="postion_left" style="width:1200px">
        <div class="title">人才搜寻正在进入人工智能时代</div>
        <div class="littleTitle">以科学严谨的测试题目，为您提供优秀人才</div>
        <img src="../assets/64.png" alt="" class="BannerImage">
      </div>
    </div>
    <!-- main -->
    <div style="height: 3930px;background:#f9fafe">
      <div class="main">
        <div class="p_relative">
          <!--  -->
          <div class="companyMain">
            <!--  -->
            <div class="flex justify-between">
              <div class="company" v-for="(item, index) in companyList" :key="index">
                <div class="flex">
                  <img :src="item.img" alt="" class="companyImg">
                  <div>
                    <div class="companyName">{{ item.name }}</div>
                    <div class="flex">
                      <div class="tips">{{ item.career }}</div>
                      <div class="tips">{{ item.num }}</div>
                    </div>
                  </div>
                </div>
                <div class="companyDesc">{{ item.desc }}</div>
                <div class="companyDetail" @click="lookDetail">查看详情></div>
              </div>
            </div>
            <!--  -->
            <div id="wrap">
              <div id="inner">
                <div class="flex">
                  <div id="first" class="flex-wrap justify-between">
                    <img :src="item.src" alt="" class="otherCompany" v-for="item in otherCompany" :key="item">
                  </div>
                  <div id="second" class="flex-wrap justify-between">
                    <img :src="item.src" alt="" class="otherCompany" v-for="item in otherCompany" :key="item">
                  </div>
                </div>
              </div>
            </div>
            <div id="wrap">
              <div id="inner">
                <div class="flex">
                  <div id="first" class="flex-wrap justify-between">
                    <img :src="item.src" alt="" class="otherCompany" v-for="item in otherCompanyTwo" :key="item">
                  </div>
                  <div id="second" class="flex-wrap justify-between">
                    <img :src="item.src" alt="" class="otherCompany" v-for="item in otherCompanyTwo" :key="item">
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- 介绍视频 -->
    <div class="videoMain">
      <div>
        <div class="title">通过“三才智聘”提高招聘效率</div>
        <div class="flex box ">
          <div class="oneBox">
            <img src="../assets/37.png" alt="" class="image">
            <div class="name">好人不来</div>
          </div>
          <div class="oneBox">
            <img src="../assets/38.png" alt="" class="image">
            <div class="name">成本高</div>
          </div>
          <div class="oneBox">
            <img src="../assets/39.png" alt="" class="image">
            <div class="name">漫长的过程</div>
          </div>
          <div class="oneBox">
            <img src="../assets/40.png" alt="" class="image">
            <div class="name">就业不匹配</div>
          </div>
        </div>
      </div>
    </div>
    <!--智能匹配  -->
    <div class="contentMain">
      <div class="intelligence">
        <div class="flex justify-between">
          <img src="../assets/41.png" alt="" class="rightImg leftImage">
          <div class="intelligenceBox" style="text-align: left;">
            <div class="name">智能匹配</div>
            <img src="../assets/50.png" alt="" class="radian">
            <div class="flex items-center">
              <img src="../assets/51.png" alt="" class="tips">
              <div class="desc">人才分析智能推荐人才</div>
            </div>
            <div class="flex items-center">
              <img src="../assets/51.png" alt="" class="tips">
              <div class="desc">查看各岗位的人力资源清单</div>
            </div>
            <div class="flex items-center">
              <img src="../assets/51.png" alt="" class="tips">
              <div class="desc">一键筛选人才，快捷邀约面试</div>
            </div>
            <div class="flex items-center">
              <img src="../assets/51.png" alt="" class="tips">
              <div class="desc">兼容所有类型的工作和就业类型</div>
            </div>
          </div>
        </div>
        <div class="flex justify-between" style="margin-top: 90px;">
          <div class="leftBox" style="text-align: left;">
            <div class="name">便捷高效</div>
            <img src="../assets/50.png" alt="" class="radian">
            <div class="flex items-center">
              <img src="../assets/51.png" alt="" class="tips">
              <div class="desc">缩短流程，无需中介</div>
            </div>
            <div class="flex items-center">
              <img src="../assets/51.png" alt="" class="tips">
              <div class="desc">直接匹配企业所需人才</div>
            </div>
            <div class="flex items-center">
              <img src="../assets/51.png" alt="" class="tips">
              <div class="desc">智能推送优秀人才</div>
            </div>
          </div>
          <img src="../assets/52.png" style="" alt="" class="rightImg rightOneImage">
        </div>
      </div>

      <!--  -->
      <div class="threeBox">
        <div class="name">高性价比</div>
        <div class="cost flex">
          <div class="leftBox">
            <div class="upfrontFee">前期费用</div>
            <div class="laterFee">后期费用</div>
          </div>
          <div class="middleBox">
            <div class="name">三才智聘</div>
            <div class="flex justify-between items-baseline">
              <div class="Fee">人力资源费用：</div>
              <div class="flex items-baseline">
                <div class="money">0</div>
                <div class="yuan">元</div>
              </div>
            </div>
            <div class="flex justify-between items-baseline">
              <div class="Fee">时间费用：</div>
              <div class="flex items-baseline">
                <div class="money">0</div>
                <div class="yuan">元</div>
              </div>
            </div>
            <div class="flex justify-between items-baseline">
              <div class="Fee">入驻费用：</div>
              <div class="flex items-baseline">
                <div class="money">0</div>
                <div class="yuan">元</div>
              </div>
            </div>
            <div class="laterFee">
              无任何后期费用
            </div>
          </div>
          <div class="rightBox">
            <div class="name">传统招聘模式</div>
            <div style="border-left: 2px solid #d9d9d9; ">
              <div>
                <div class="flex justify-between items-baseline">
                  <div class="Fee">人力资源费用：</div>
                  <div class="flex items-baseline">
                    <div class="money">4-5K</div>
                    <div class="qianyuan">元</div>
                    <div class="yuan">/人 </div>
                  </div>
                </div>
                <div class="flex justify-between items-baseline">
                  <div class="Fee">时间费用：</div>
                  <div class="flex items-baseline">
                    <div class="money">10K</div>
                    <div class="qianyuan">元</div>
                  </div>
                </div>
                <div class="flex justify-between items-baseline">
                  <div class="Fee">入驻费用：</div>
                  <div class="flex items-baseline">
                    <div class="money">10K </div>
                    <div class="qianyuan">元</div>
                  </div>
                </div>
              </div>
              <div class="laterFee">
                无任何后期费用
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!--来自企业的评价  -->
    <div class="evaluate">
      <div class="evaluateOne">
        <div class="name">来自企业的评价</div>

        <div id="wrap">
          <div id="inner">
            <div class="flex">
              <div class="flex">
                <div class="evaluateBox" v-for="(item, index) in evaluateList" :key="index">
                  <img :src="item.img" alt="" class="bigImg">
                  <div class="title">{{ item.name }}</div>
                  <div class="look" @click="lookDetail">查看企业></div>
                </div>
              </div>
              <div class="flex">
                <div class="evaluateBox" v-for="(item, index) in evaluateList" :key="index">
                  <img :src="item.img" alt="" class="bigImg">
                  <div class="title">{{ item.name }}</div>
                  <div class="look" @click="lookDetail">查看企业></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="name">媒体</div>
        <div class="flex" style="margin:30px 0 0 0px;padding-bottom: 82px;">
          <div class="evaluateOtherBox flex justify-center items-center" v-for="(item, index) in evaluateOtherList"
            :key="index">
            <img :src="item.img" alt="" class="img">
          </div>
        </div>
      </div>
    </div>


    <!-- 链接梦想 -->
    <div class="dream p_relative ">
      <div class="postion_left flex align-center" style="width: 1200px;">
        <img src="../assets/28.png" alt="" class="earth">
        <img src="../assets/29.png" alt="" class="name">
        <img src="../assets/30.png" alt="" class="hand">
        <img src="../assets/31.png" alt="" class="people">
      </div>
    </div>

    <!-- footer -->
    <page-footer></page-footer>
    <!-- <div class="lastBox">
      <div class="footer">
        <div class="flex oneBox" style="">
          <div>
            <img src="../assets/32.png" alt="" class="logo">
            <div class="one_title">快速求职，从“你好” 第三代AI人力资源匹配APP开始，直接连接企业和求职者</div>
            <div class="flex">
              <img src="../assets/33.png" alt="" class="weixin">
              <img src="../assets/34.png" alt="" class="weixin">
            </div>
            <div class="one_title">公司地址： 河南省洛阳市老城区青年创业大厦24层</div>
          </div>
          <div class="twoBox">
            <div class="title">导航</div>
            <div class="name" @click="navClick(0)">首页</div>
            <div class="name" @click="navClick(1)">招聘的公司</div>
            <div class="name" @click="navClick(2)">评估体系</div>
            <div class="name" @click="navClick(3)">帮助中心</div>
          </div>
          <div class="flex">
            <div class="threeBox">
              <img src="../assets/35.png" alt="" class="wxCode">
              <div class="name">微信小程序</div>
            </div>
            <div class="threeBox">
              <img src="../assets/36.png" alt="" class="wxCode">
              <div class="name">微信公众号</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="hnpx">
          河南品讯网络科技有限公司
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  inject: ['reload'],
  data () {
    return {
      navIndex: 1,
      navList: ['首页', '招聘的公司', '评估体系', '帮助中心'],
      companyList: [{
        img: require('../assets/zw-02.png'),
        name: '在洛阳',
        career: '互联网',
        num: '200人以上',
        desc: '五险一金，定期体检，加班补助，年终奖年终奖'
      }, {
        img: require('../assets/zw-02.png'),
        name: '在洛阳',
        career: '互联网',
        num: '200人以上',
        desc: '五险一金，定期体检，加班补助，年终奖年终奖'
      }, {
        img: require('../assets/zw-02.png'),
        name: '在洛阳',
        career: '互联网',
        num: '200人以上',
        desc: '五险一金，定期体检，加班补助，年终奖年终奖'
      }],
      otherCompany: [
        { src: require('../assets/zw-01.png') },
        { src: require('../assets/zw-01.png') },
        { src: require('../assets/zw-01.png') },
        { src: require('../assets/zw-01.png') },
        { src: require('../assets/zw-01.png') },
      ],
      otherCompanyTwo: [
        { src: require('../assets/zw-01.png') },
        { src: require('../assets/zw-01.png') },
        { src: require('../assets/zw-01.png') },
        { src: require('../assets/zw-01.png') },
        { src: require('../assets/zw-01.png') },
      ],
      // otherCompany: [
      //   { src: require('../assets/7.png') },
      //   { src: require('../assets/8.png') },
      //   { src: require('../assets/9.png') },
      //   { src: require('../assets/10.png') },
      //   { src: require('../assets/11.png') },
      // ],
      // otherCompanyTwo: [
      //   { src: require('../assets/12.png') },
      //   { src: require('../assets/13.png') },
      //   { src: require('../assets/14.png') },
      //   { src: require('../assets/15.png') },
      //   { src: require('../assets/16.png') },
      // ],
      // evaluateList: [{
      //   img: require('../assets/42.png'),
      //   name: '三才智聘非常重视体验，倡导“连接梦想，发现人才”',
      // }, {
      //   img: require('../assets/43.png'),
      //   name: '我们能明显感受三才智聘对招聘服务的赋能',
      // }, {
      //   img: require('../assets/44.png'),
      //   name: '三才智聘通过“数字化”解决招聘中的“痛”',
      // }],
      evaluateList: [{
        img: require('../assets/zw-03.png'),
        name: '三才智聘非常重视体验，倡导“连接梦想，发现人才”',
      }, {
        img: require('../assets/zw-03.png'),
        name: '我们能明显感受三才智聘对招聘服务的赋能',
      }, {
        img: require('../assets/zw-03.png'),
        name: '三才智聘通过“数字化”解决招聘中的“痛”',
      }],
      // evaluateOtherList: [{
      //   img: require('../assets/45.png'),
      // }, {
      //   img: require('../assets/46.png'),
      // }, {
      //   img: require('../assets/47.png'),
      // }, {
      //   img: require('../assets/48.png'),
      // }, {
      //   img: require('../assets/49.png'),
      // }],
      evaluateOtherList: [{
        img: require('../assets/zw-04.png'),
      }, {
        img: require('../assets/zw-04.png'),
      }, {
        img: require('../assets/zw-04.png'),
      }, {
        img: require('../assets/zw-04.png'),
      }, {
        img: require('../assets/zw-04.png'),
      }],
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getLeftImgage()
      this.getRight()
      this.getLeft();
      this.getRightOne()
    });
    this.$forceUpdate()
  },
  beforeUnmount () {


    window.removeEventListener('scroll', this.handleScrollThree);
    window.removeEventListener('scroll', this.handleScrollFour);
    window.removeEventListener('scroll', this.handleScrollFive);
    window.removeEventListener('scroll', this.handleScrollSix);
    console.log('销毁2');
    this.$forceUpdate()
  },
  methods: {
    lookDetail () {
      ElMessage({
        message: '正在建设中，敬请期待......',
        type: 'warning',
        offset: 100,
        customClass: 'messageIndex'
      })
    },
    getLeft () {
      //实例挂载完毕前
      var box = document.querySelector('.leftBox');
      var isVisible = false;

      this.handleScrollFive = function () {
        var boxPosition = box.getBoundingClientRect().top;
        var windowHeight = window.innerHeight;

        if (boxPosition < windowHeight + 300 && !isVisible) {
          box.classList.remove('animate-out');
          box.classList.add('animate-in');
          isVisible = true;
        } else if (boxPosition >= windowHeight && isVisible) {
          box.classList.remove('animate-in');
          box.classList.add('animate-out');
          isVisible = false;
        }
      }
      window.addEventListener('scroll', this.handleScrollFive);
    },
    getLeftImgage () {
      //实例挂载完毕前
      var box = document.querySelector('.leftImage');
      var isVisible = false;

      this.handleScrollSix = function () {
        var boxPosition = box.getBoundingClientRect().top;
        var windowHeight = window.innerHeight;

        if (boxPosition < windowHeight + 300 && !isVisible) {
          box.classList.remove('animate-out');
          box.classList.add('animate-in');
          isVisible = true;
        } else if (boxPosition >= windowHeight && isVisible) {
          box.classList.remove('animate-in');
          box.classList.add('animate-out');
          isVisible = false;
        }
      }
      window.addEventListener('scroll', this.handleScrollSix);
    },
    getRightOne () {
      //
      var box = document.querySelector('.rightOneImage');
      var isVisible = false;

      this.handleScrollThree = function () {
        var boxPosition = box.getBoundingClientRect().top;
        var windowHeight = window.innerHeight;

        if (boxPosition < windowHeight && !isVisible) {
          box.classList.remove('animate-out-right');
          box.classList.add('animate-in-right');
          isVisible = true;
        } else if (boxPosition >= windowHeight && isVisible) {
          box.classList.remove('animate-in-right');
          box.classList.add('animate-out-right');
          isVisible = false;
        }
      }
      window.addEventListener('scroll', this.handleScrollThree);
    },
    getRight () {
      //
      var box = document.querySelector('.intelligenceBox');
      var isVisible = false;

      this.handleScrollFour = function () {
        var boxPosition = box.getBoundingClientRect().top;
        var windowHeight = window.innerHeight;

        if (boxPosition < windowHeight && !isVisible) {
          box.classList.remove('animate-out-right');
          box.classList.add('animate-in-right');
          isVisible = true;
        } else if (boxPosition >= windowHeight && isVisible) {
          box.classList.remove('animate-in-right');
          box.classList.add('animate-out-right');
          isVisible = false;
        }
      }
      window.addEventListener('scroll', this.handleScrollFour);
    },
    navClick (index) {
      this.navIndex = index
      this.reload()
      if (index == 1) {

        document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
        this.$router.push({
          path: '/companyView'
        })
      } else if (index == 2) {
        this.$router.push({
          path: '/estimateView'
        })
        document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
      } else if (index == 3) {
        this.$router.push({
          path: '/helpView'
        })
        document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
      } else {
        this.$router.push({
          path: '/',
        })
        document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
      }
    },
    campusRecruitClick () {
      let routeData = this.$router.resolve({ path: '/campusRecruit', });
      document.getElementById('home').scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
      window.open(routeData.href, '_blank');
    },
    questionClick (index) {
      this.questionIndex = index
    },
    questionNoClick () {
      this.questionIndex = -1
    },

  }
}
</script>
<style lang="scss" scoped>
.home {
  position: relative;
  z-index: 99;
}

// .header {
//   width: 100%;
//   height: 80px;
//   background: #fff;
//   position: fixed;
//   left: 0px;
//   top: 0px;

//   // padding: 0px 360px;
//   .headerBox {
//     max-width: 1200px;
//     margin: 0 auto;
//   }

//   line-height: 80px;
//   z-index: 9999;

//   .logo {
//     width: 110px;
//     height: 26px;
//     margin-top: 27px;
//   }

//   .title {
//     font-size: 16px;
//     font-family: PingFang SC, PingFang SC-500;
//     font-weight: 500;
//     color: #000000;
//     margin-left: 120px;
//     cursor: pointer;
//   }

//   .title:hover {
//     color: #4d3db9;
//   }

//   .choose_title {
//     color: #4d3db9;
//     border-bottom: 4px solid #4d3db9;
//     margin-bottom: 16px;
//   }

//   .schoolBtn {
//     width: 120px;
//     height: 40px;
//     background: linear-gradient(108deg, #b17bd2, #4d3db9 100%);
//     border-radius: 10px;
//     font-size: 16px;
//     font-family: PingFang SC, PingFang SC-500;
//     font-weight: 500;
//     text-align: center;
//     color: #ffffff;
//     line-height: 40px;
//     margin: 20px 0 0 240px;
//     cursor: pointer;
//   }
// }

//
.banner {
  position: absolute;
  left: 0px;
  top: 70px;
  width: 100%;
  height: 860px;
  background-image: url('../assets/63.png');

  .title {
    font-size: 70px;
    font-family: PingFang SC, PingFang SC-600;
    font-weight: 600;
    text-align: center;
    color: #333333;
    line-height: 70px;
    margin: 298px 0 0 0;
    white-space: nowrap;
  }

  .littleTitle {
    margin: 40px 0 324px 0;
    font-size: 28px;
    font-family: PingFang SC, PingFang SC-400;
    font-weight: 400;
    text-align: center;
    color: #333333;
  }

  .BannerImage {
    width: 440px;
    height: 590px;
    position: absolute;
    right: 0px;
    top: 275px;
  }
}

//
.main {
  position: absolute;
  // left: 0px;
  top: 940px;
  right: 0px;
  background: #f9fafe;
  left: 50%;
  transform: translateX(-50%);
  width: 1200px;
  height: 495px;
}

//
.companyMain {
  width: 1200px;
  // margin: 0 0px 50px 360px;
  position: absolute;
  top: -100px;

  .company {
    height: 200px;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 0px 24px 0px rgba(234, 231, 255, 0.80);
    padding: 30px 0 0 20px;
    margin: 0 0px 15px 0;
    box-sizing: border-box;

    .companyImg {
      width: 80px;
      height: 80px;
      border-radius: 20px;
      margin-right: 20px;
    }

    .companyName {
      font-size: 28px;
      font-family: PingFang SC, PingFang SC-500;
      font-weight: 500;
      color: #000000;
      margin-bottom: 12px;
      text-align: left;
    }

    .tips {
      padding: 0 10px;
      height: 32px;
      background: #f8f8f8;
      border-radius: 5px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      text-align: center;
      color: #666666;
      line-height: 32px;
      margin-right: 10px;
    }

    .companyDesc {
      margin-top: 18px;
      width: 304px;
      height: 16px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      text-align: LEFT;
      color: #999999;
      line-height: 16px;
      overflow: hidden;
      /*文本超出隐藏*/
      text-overflow: ellipsis;
      /*文本超出显示省略号*/
      white-space: nowrap;
      /*超出的空白区域不换行*/
    }

    .companyDetail {
      width: 380px;
      height: 44px;
      background: #faf9fd;
      border-radius: 0px 0px 20px 20px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      text-align: center;
      color: #156bef;
      line-height: 44px;
      margin: 16px 0 0 -20px;
      cursor: pointer;
    }
  }

  //
  #wrap {
    overflow: hidden;
    position: relative;
    width: 1200px;
    height: 140px;
    white-space: nowrap;

    #inner {
      position: absolute;
      animation: slide 15s linear infinite normal;
    }

    #inner:hover {
      animation: 15s slide linear infinite paused;
    }

    #first {
      display: inline-block;
    }

    #second {
      display: inline-block;
    }

    @keyframes slide {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(-50%);
      }
    }

    .otherCompany {
      height: 140px;
      border: 1px solid #fafafa;
      border-radius: 10px;
      margin: 25px 25px 0 0;
      box-sizing: border-box;
    }
  }

}

//
.videoMain {
  background: #ffffff;
  width: 100%;
  margin-top: 445px;
  position: absolute;
  top: 940px;
  right: 0px;
  height: 460px;
  left: 50%;
  transform: translateX(-50%);

  .title {
    font-size: 44px;
    font-family: PingFang SC, PingFang SC-600;
    font-weight: 600;
    text-align: center;
    color: #000000;
    padding: 50px 0 40px 0;
  }

  .box {
    // margin-left: 418px;
    width: 1200px;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;

    .oneBox {
      margin-right: 55px;

      .image {
        width: 230px;
        height: 230px;
      }


      .name {
        margin: 22px 0 50px 0;
        font-size: 28px;
        font-family: PingFang SC, PingFang SC-500;
        font-weight: 500;
        text-align: center;
        color: #333333;
      }
    }
  }


}

//
.contentMain {
  padding: 90px 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 1840px;
  width: 1200px;

  .intelligence {
    overflow: hidden;

    .name {
      margin: 50px 0 0px 0;
      font-size: 44px;
      font-family: PingFang SC, PingFang SC-600;
      font-weight: 600;
      text-align: LEFT;
      color: #000000;
    }

    .radian {
      width: 136px;
      height: 12px;
      margin: -4px 0px 12px 17px;
    }

    .tips {
      width: 34px;
      height: 34px;
      margin: 14px 20px 0 0;
    }

    .desc {
      font-size: 28px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      text-align: LEFT;
      color: #666666;
      margin: 17px 0 0 0;
    }
  }


  .rightImg {
    width: 490px;
    height: 530px;
  }

  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slideOutToLeft {
    0% {
      transform: translateX(0);
      opacity: 1;
    }

    100% {
      transform: translateX(-100%);
      opacity: 0;
    }
  }


  @keyframes slideOutToRight {
    0% {
      transform: translateX(0);
      opacity: 1;
    }

    100% {
      transform: translateX(100%);
      opacity: 0;
    }
  }

  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .intelligenceBox {
    opacity: 0;
  }

  .animate-in {
    animation-name: slideInFromLeft;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    opacity: 1;
  }

  .animate-out {
    animation-name: slideOutToLeft;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    opacity: 1;
  }

  .animate-in-right {
    animation-name: slideInFromRight;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    opacity: 1;
  }

  .animate-out-right {
    animation-name: slideOutToRight;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    opacity: 1;
  }

  .threeBox {
    .name {
      margin: 120px 0 66px 0;
      text-align: center;
      font-size: 44px;
      font-family: PingFang SC, PingFang SC-600;
      font-weight: 600;
      text-align: center;
      color: #000000;
    }

    .cost {
      width: 1200px;
      height: 540px;
      background: #ffffff;
      border: 2px solid #f1f3fd;
      border-radius: 40px;
      // margin-left: 360px;
      padding: 40px;
      box-sizing: border-box;

      .leftBox {
        width: 206px;
        height: 460px;
        background: #4d3db9;
        border-radius: 20px;

        .upfrontFee {
          font-size: 24px;
          font-family: PingFang SC, PingFang SC-600;
          font-weight: 600;
          text-align: center;
          color: #ffffff;
          padding: 178px 0;
          border-bottom: 2px solid #ffffff;
        }

        .laterFee {
          font-size: 24px;
          font-family: PingFang SC, PingFang SC-600;
          font-weight: 600;
          text-align: center;
          color: #ffffff;
          padding: 18px 0;
        }
      }

      //
      .middleBox {
        width: 456px;

        .name {
          width: 300px;
          height: 70px;
          background: #4d3db9;
          border-radius: 35px;
          font-size: 28px;
          font-family: PingFang SC, PingFang SC-500;
          font-weight: 500;
          text-align: center;
          color: #ffffff;
          line-height: 70px;
          margin: 0 0 0px 78px;
        }

        .Fee {
          font-size: 24px;
          font-family: PingFang SC, PingFang SC-500;
          font-weight: 500;
          text-align: LEFT;
          color: #4d3db9;
          width: 168px;
          margin: 0 0 24px 40px;
        }

        .money {
          font-size: 60px;
          font-family: PingFang SC, PingFang SC-600;
          font-weight: 600;
          text-align: left;
          color: #4d3db9;
        }

        .yuan {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-500;
          text-align: LEFT;
          color: #4d3db9;
          margin-right: 40px;
        }

        .laterFee {
          font-size: 28px;
          font-family: PingFang SC, PingFang SC-500;
          font-weight: 500;
          text-align: center;
          color: #4d3db9;
          border-top: 2px solid #dedede;
          margin: 30px 0 0 0;
          padding-top: 15px;
        }
      }

      .rightBox {
        width: 456px;

        .name {
          width: 300px;
          height: 70px;
          background: #929292;
          border-radius: 35px;
          font-size: 28px;
          font-family: PingFang SC, PingFang SC-500;
          font-weight: 500;
          text-align: center;
          color: #ffffff;
          line-height: 70px;
          margin: 0 0 0px 78px;
        }

        .Fee {
          font-size: 24px;
          font-family: PingFang SC, PingFang SC-500;
          font-weight: 500;
          text-align: LEFT;
          color: #929292;
          width: 168px;
          display: inline-block;
          margin: 0 0 24px 40px;
        }

        .money {
          font-size: 60px;
          font-family: PingFang SC, PingFang SC-600;
          font-weight: 600;
          text-align: left;
          color: #929292;
        }

        .qianyuan {
          font-size: 28px;
          font-family: PingFang SC, PingFang SC-500;
          text-align: LEFT;
          color: #929292;
        }

        .yuan {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-500;
          text-align: LEFT;
          color: #929292;
        }

        .laterFee {
          font-size: 28px;
          font-family: PingFang SC, PingFang SC-500;
          font-weight: 500;
          text-align: center;
          color: #929292;
          border-top: 2px solid #dedede;
          margin: 30px 0 0 0;
          padding-top: 15px;
        }
      }
    }
  }
}

//
.evaluate {
  background: #ffffff;
  position: absolute;
  top: 3920px;
  width: 100%;
  height: 910px;

  .evaluateOne {
    width: 1200px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }

  .name {
    font-size: 44px;
    font-family: PingFang SC, PingFang SC-600;
    font-weight: 600;
    text-align: center;
    color: #000000;
    padding: 60px 0 30px 0;
  }

  #wrap {
    overflow: hidden;
    position: relative;
    width: 1200px;
    height: 290px;
    white-space: nowrap;
    // margin-left: 360px;
    padding-top: 30px;

    #inner {
      position: absolute;
      animation: slide 15s linear infinite normal;
    }

    #inner:hover {
      animation: 15s slide linear infinite paused;
    }


    @keyframes slide {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(-50%);
      }
    }
  }

  .evaluateBox {
    width: 380px;
    height: 260px;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 0px 24px 0px rgba(234, 231, 255, 0.80);
    box-sizing: border-box;
    text-align: center;
    padding: 24px 0 30px 0;
    margin-right: 30px;

    .bigImg {
      width: 140px;
      height: 46px;
    }

    .title {
      margin: 20px 20px 0 20px;
      border-top: 1px solid #f6f6f6;
      font-size: 24px;
      font-family: PingFang SC, PingFang SC-500;
      font-weight: 500;
      text-align: CENTER;
      color: #333333;
      padding: 20px 0 38px 0;
      width: 288px;
      white-space: initial;
    }

    .look {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      color: #533fdb;
      cursor: pointer;
    }
  }

  .evaluateOtherBox {
    box-sizing: border-box;
    text-align: center;
    margin-right: 25px;
    width: 220px;
    height: 200px;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 0px 24px 0px rgba(234, 231, 255, 0.80);

    .img {
      width: 114px;
    }
  }


}

//
.dream {
  height: 245px;
  top: 4850px;
  width: 100%;
  z-index: 999;
  position: absolute;
  background: #f9fafe;

  .earth {
    width: 120px;
    height: 120px;
    // margin: 10px 187px 0 -310px;
  }

  .name {
    width: 537px;
    height: 41px;
    margin: 102.5px 0 0 187px;
  }

  .hand {
    // position: absolute;
    // right: 642px;
    // top: 124px;
    width: 62px;
    height: 52px;
    margin: 110px 0 0 -10px;
  }

  .people {
    width: 180px;
    height: 180px;
    // position: fixed;
    // right: 360px;
    // top: 112px;
    margin: 115px 0 0 102px;
  }
}

//
.lastBox {
  position: absolute;
  top: 5040px;
  background: #000002;
  width: 100%;
  left: 0px;
  height: 440px;

  .hnpx {
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-400;
    font-weight: 400;
    text-align: center;
    color: #444444;
    padding: 66px 0 22px 0;
    background: #000002;
  }
}

.footer {
  padding: 80px 0 0 0px;
  text-align: left;
  height: 340px;

  .oneBox {
    width: 1200px;
    padding-bottom: 36px;
    border-bottom: 1px solid #1b1b1b;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }

  .logo {
    width: 150px;
    height: 50px;
    margin-bottom: 30px;
  }

  .one_title {
    width: 388px;
    height: 48px;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-400;
    font-weight: 400;
    text-align: LEFT;
    color: #ffffff;
    line-height: 28px;
    margin-bottom: 40px;
  }

  .weixin {
    width: 34px;
    height: 34px;
    margin: 0 20px 46px 0;
  }

  .twoBox {
    margin: 0 0 0 42px;
    width: 300px;
    border-left: 1px solid #1b1b1b;
    border-right: 1px solid #1b1b1b;
    padding: 0 160px 0 60px;
    box-sizing: border-box;

    .title {
      width: 36px;
      height: 18px;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-500;
      font-weight: 500;
      text-align: LEFT;
      color: #ffffff;
      line-height: 18px;
      margin-bottom: 48px;
    }

    .name {
      width: 80px;
      height: 16px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      text-align: LEFT;
      color: #ffffff;
      line-height: 16px;
      margin-bottom: 48px;
      cursor: pointer;
    }

    .name:hover {
      color: #4d3db9;
    }
  }

  .threeBox {
    .wxCode {
      width: 140px;
      height: 140px;
      margin: 50px 0 30px 50px;
    }

    .name {
      width: 80px;
      height: 16px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      line-height: 16px;
      margin-left: 80px;
    }
  }


}
</style>